import styled from "styled-components";
import { Font, Color, breakpoints, ColorDark } from "@/shared/Constants";

export const ContainerText = styled.div`
overflow-x:clip;
  p {
    ${Font.clarinVarSub2Regular};
    color: ${Color.black000};
    font-size: 23px;
    line-height: 33px;
    letter-spacing: -0.12px;
    padding: 17px 0;

    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      }
    }
  
    ${breakpoints.phone} {
      max-width: 600px;
      font-size: 21px;
      line-height: 30px;
      letter-spacing: -0.1px;
    }
    strong {
      ${Font.clarinVarSub2Bold};
    }
  }
  strong {
    ${Font.clarinVarSub2Bold};
  }
  a {
    color: ${Color.black000};
    border-bottom: 1px solid ${Color.mainColor};
    display: unset;
    &:hover {
      border-bottom: 1px solid ${Color.black000};
      ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        border-bottom: 1px solid ${ColorDark.textLight};
        }
      }
    }
    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      border-bottom: 1px solid ${ColorDark.mainColor};
      }
    }
  }
  h2 {
    ${Font.sansBold};
    color: ${Color.black000};
    font-size: 25px;
    line-height: 33px;
    letter-spacing: -0.13px;
    padding: 17px 0;
    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      }
    }
    ${breakpoints.phone} {
      font-size: 25px;
      line-height: 28px;
    }
  }
  h3 {
    ${Font.clarinVarSub2Bold};
    margin: 0;
    font-size: 26px;
    line-height: 33px;
    letter-spacing: -0.28px;
    color: ${Color.mainColor};
    padding-top: 17px;
    margin-bottom: -17px;
    width: 100%;
    margin-bottom: 5px;
    ${breakpoints.darkMode} {

      [data-theme='dark'] & {
      color: ${ColorDark.mainColor};
      }
    }
    ${breakpoints.phone} {
      line-height: 30px;
    }
  }
  ol,
  ul {
    padding-left: 2rem;
    li {
      ${Font.clarinVarSub2Regular};
      color: ${Color.black000};
      font-size: 23px;
      line-height: 33px;
      letter-spacing: -0.12px;
      padding-bottom: 17px;
      ${breakpoints.darkMode} {

        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 21px;
        line-height: 30px;
        letter-spacing: -0.1px;
      }
    }
  }
  ul li {
    list-style-type: none;
    &:before {
      background: ${Color.grey};
      border-radius: 4px;
      content: " ";
      display: inline-flex;
      height: 4px;
      width: 4px;
      margin-right: 6px;
      transition-duration: 0.3s;
      vertical-align: middle;
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        background: ${ColorDark.textLight};
        }
      }
    }
  }
  ol {
    margin: 0;
    li {
      list-style-type: unset;
    }
  }
  blockquote,
  span[type="highlighted"] {
    ${Font.clarinVarSub2Regular};
    margin: 0;
    font-size: 28px;
    line-height: 36px;
    letter-spacing: -0.28px;
    color: ${Color.black000};
    ${breakpoints.darkMode} {
      [data-theme='dark'] & {
      color: ${ColorDark.textLight};
      }
    }
    ${breakpoints.phone} {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.24px;
    }
  }
  span[type="highlighted"] {
    width: 70%;
    display: block;
  }

  blockquote {
    position: relative;
    width: 70%;
    p {
      ${Font.clarinVarSub2Regular};
      margin: 0;
      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.28px;
      color: ${Color.black000};
      ${breakpoints.darkMode} {
        [data-theme='dark'] & {
        color: ${ColorDark.textLight};
        }
      }
      ${breakpoints.phone} {
        font-size: 24px;
        line-height: 28px;
        letter-spacing: -0.24px;
      }
    }
  }
`;
