import { deleteLinkByHref, formatTextLinkTags } from "helpers";
import { ContainerText } from "./style";

const TextBody = ({ content, elementBodyId }) => {
  let textForRender = deleteLinkByHref(content.value,'undefined');
  textForRender = deleteLinkByHref(textForRender,'unsafe:[object Object]');
  textForRender = formatTextLinkTags(textForRender);
  if(textForRender && textForRender.includes('web-clarin.lilax.io')) {
    textForRender = textForRender?.replaceAll('web-clarin.lilax.io','www.clarin.com');
  }

  return (
    <ContainerText className="container-text text-embed" suppressHydrationWarning
    dangerouslySetInnerHTML={{
      __html: textForRender,
    }}
    id={elementBodyId}
    />
  );
};
export default TextBody;
